import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";

export class Icon {
  description: string;
  path: string;

  static Register(
    id: string,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer
  ) {
    const icon = Icons.find((i) => i.description === id);
    iconRegistry.addSvgIcon(
      icon.description,
      sanitizer.bypassSecurityTrustResourceUrl(icon.path)
    );
  }
}

export const Icons: Icon[] = [
  { description: "close", path: "assets/ui/icons/close_filled.svg" },
  { description: "increase-font", path: "assets/ui/icons/increase-font.svg" },
  { description: "decrease-font", path: "assets/ui/icons/decrease-font.svg" },
  { description: "underline", path: "assets/ui/icons/underline.svg" },
  { description: "smile", path: "assets/ui/icons/ic_smile.svg" },
  { description: "sad", path: "assets/ui/icons/ic_smile_sad.svg" },
  { description: "ok", path: "assets/ui/icons/ic_smile_ok.svg" },
  { description: "happy", path: "assets/ui/icons/ic_smile_happy.svg" },
  { description: "pendente", path: "assets/ui/icons/cloud_waiting.svg" },
  { description: "offline", path: "assets/ui/icons/cloud_cross.svg" },
  { description: "download", path: "assets/ui/icons/download.svg" },
  { description: "update-lei", path: "assets/ui/icons/update-lei.svg" },
  { description: "lei-ok", path: "assets/ui/icons/lei-ok.svg" },
  { description: "timer-parado", path: "assets/ui/icons/timer-parado.svg" },
  { description: "timer-ativo", path: "assets/ui/icons/timer-ativo.svg" },
  {
    description: "facebook-f",
    path: "assets/icons/facebook/icons8-f-do-facebook-32.svg",
  },
  {
    description: "instagram",
    path: "assets/icons/instagram/icons8-instagram-32.svg",
  },
  { description: "crown", path: "assets/icons/crown.svg" },
  //logo Ux/Ui 2024
  { description: "logo-lg", path: "assets/ui/logo-lg.svg" },
  { description: "logo-md", path: "assets/ui/logo-md.svg" },
  { description: "logo-sm", path: "assets/ui/logo-sm.svg" },
  //icons Ux/Ui 2024
  { description: "user-empty", path: "assets/ui/icons/user-empty.svg" },
  { description: "xmark-circle", path: "assets/ui/icons/xmark-circle.svg" },
  { description: "search", path: "assets/ui/icons/search.svg" },
  { description: "bell", path: "assets/ui/icons/bell.svg" },
  {
    description: "clock-rotate-left",
    path: "assets/ui/icons/clock-rotate-left.svg",
  },
  {
    description: "question-circle",
    path: "assets/ui/icons/question-circle.svg",
  },
  { description: "caret-right", path: "assets/ui/icons/caret-right.svg" },
  { description: "caret-down", path: "assets/ui/icons/caret-down.svg" },
  { description: "caret-up", path: "assets/ui/icons/caret-up.svg" },
  { description: "bars", path: "assets/ui/icons/bars.svg" },
  { description: "star", path: "assets/ui/icons/star.svg" },
  { description: "chevron-left", path: "assets/ui/icons/chevron-left.svg" },
  { description: "xmark", path: "assets/ui/icons/xmark.svg" },
];
