<div
  class="navegacao-pesquisa-leis-cotainer"
  type="button"
  cdkOverlayOrigin
  #searchOverlay="cdkOverlayOrigin"
  #serchViewOverlay
  [ngClass]="{ opened: isOverlayOpen }"
>
  <button mat-icon-button [disableRipple]="true">
    <mat-icon
      svgIcon="search"
      class="material-icons-outlined navegacao-icon-pesquisa"
    ></mat-icon>
  </button>

  <input
    #searchInput
    placeholder="O que você está buscando?"
    class="navegacao-input"
    [(ngModel)]="searchText"
    (click)="OverlayOpen()"
    (ngModelChange)="searchText_Change()"
    [cdkTrapFocusAutoCapture]="isOverlayOpen"
    [cdkTrapFocus]="isOverlayOpen"
  />
  <button
    *ngIf="searchText?.length > 0"
    mat-icon-button
    mat-button
    (click)="searchText_clear()"
  >
    <mat-icon
      svgIcon="xmark-circle"
      class="material-symbols-outlined navegacao-icon-clear"
    ></mat-icon>
  </button>
</div>

<ng-template
  *ngIf="exibirNavegacaoPesquisaMobile$"
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="searchOverlay"
  [cdkConnectedOverlayOpen]="isOverlayOpen"
  cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayWidth]="OverlayWith$ | async"
  (detach)="isOverlayOpen = false"
  (backdropClick)="OverlayOpen()"
>
  <div class="navegacao-pesquisa-mat-overlay">
    <span class="navegacao-pesquisa-label">
      <p class="navegacao-pesquisa-paragraph">Filtrar por</p>
    </span>

    <mat-chip-list
      class="navegacao-pesquisa-chip-list"
      aria-label="Filtrar por"
    >
      <mat-chip
        class="navegacao-pesquisa-chip chip0"
        (click)="alterarModoOrdenacao()"
      >
        <span class="navegacao-pesquisa-chip-container">
          <p class="navegacao-pesquisa-chip-paragraph">Legislação</p>
        </span>
      </mat-chip>

      <mat-chip
        class="navegacao-pesquisa-chip chip1"
        (click)="toggleFiltroFavoritos()"
      >
        <span class="navegacao-pesquisa-chip-container">
          <p class="navegacao-pesquisa-chip-paragraph">Favoritos</p>
        </span>
      </mat-chip>
    </mat-chip-list>

    <!-- <mat-divider *ngIf="(listaRecentSearche$ | async).length > 0" class="navegacao-pesquisa-divider"></mat-divider>

    <span *ngIf="(listaRecentSearche$ | async).length > 0" class="navegacao-pesquisa-label">
      <p class="navegacao-pesquisa-paragraph">Pesquisas recentes</p>
    </span>

  <span class="navegacao-pesquisa-recente " *ngFor="let item of listaRecentSearche$ | async as list">
    <span  class="navegacao-pesquisa-recente-icon-clock">
      <button mat-icon-button [disableRipple]="true">
        <mat-icon svgIcon="clock-rotate-left"></mat-icon>
      </button>
    </span>
      <p class="navegacao-pesquisa-recente-paragraph">{{item}}</p>
    <span class="navegacao-pesquisa-recente-icon-close">
      <button mat-icon-button [disableRipple]="true" (click)="removeRecentSearche(item)">
        <mat-icon svgIcon="xmark"></mat-icon>
      </button>
    </span>
  </span> -->

    <!-- <mat-divider class="navegacao-pesquisa-divider"></mat-divider>

    <span class="navegacao-pesquisa-label">
      <p class="navegacao-pesquisa-paragraph">Ordenar por</p>
    </span>
    <mat-chip-list
      class="navegacao-pesquisa-chip-list"
      aria-label="Filtrar por"
    >
      <mat-chip
        class="navegacao-pesquisa-chip"
        *ngFor="let modo of opcoesModoOrdenacao; let i = index"
        (click)="alterarModoOrdenacao(modo)"
        backgroundContrast
        [ngClass]="'chip' + i"
      >
        <span class="navegacao-pesquisa-chip-container">
          <p class="navegacao-pesquisa-chip-paragraph">
            {{ modo.label }}
          </p></span
        ></mat-chip
      >
    </mat-chip-list> -->

    <mat-divider class="navegacao-pesquisa-divider"></mat-divider>

    <span class="navegacao-pesquisa-label">
      <p class="navegacao-pesquisa-paragraph">Resultados encotrados</p>
    </span>

    <ng-container *ngIf="(listaLeis$ | async)?.length > 0; else Empty">
      <cdk-virtual-scroll-viewport
        itemSize="10"
        minBufferPx="200"
        maxBufferPx="400"
        class="navegacao-pesquisa-virtual-scroll-viewport"
        (scrolledIndexChange)="getNextData($event)"
      >
        <app-item-navegacao-pesquisa-leis
          *cdkVirtualFor="let item of listaLeis$"
          [setItem]="item"
          class="navegacao-pesquisa-virtual-scroll-item"
          (favorito_mudou)="favoritoAlterardo(item)"
          (click)="selecionar(item)"
        >
        </app-item-navegacao-pesquisa-leis>
      </cdk-virtual-scroll-viewport>
    </ng-container>
  </div>
</ng-template>

<ng-template #Empty>
  <button class="button-nenhum-resultado" mat-stroked-button>
    <div class="msg-nenhum-resultado" (click)="abrirBusca()">
      <mat-icon class="icon-nenhum-resultado">search</mat-icon>

      <p class="paragraph-nenhum-resultado">
        Pesquisar profundamente em todos os documentos
      </p>
    </div>
  </button>
</ng-template>
