import { element } from "protractor";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Observable, of } from "rxjs";
import { MAT_SELECT_CONFIG } from "@angular/material/select";
import { OverlayContainer } from "@angular/cdk/overlay";
import { UiService } from "src/app/services/ui.service";
import { UsuarioGuiasService } from "src/app/services/data-services/usuario.guias.service";
import { Guia } from "src/app/models/Guia";
import { T } from "@angular/cdk/keycodes";
import { UsuarioEstatisticasService } from "src/app/services/data-services/usuario.estatisticas.service";

@Component({
  selector: "app-navegacao-leis-ramos-direito",
  templateUrl: "./navegacao-leis-ramos-direito.component.html",
  styleUrls: ["./navegacao-leis-ramos-direito.component.scss"],
})
export class NavegacaoLeisRamosDireitoComponent implements OnInit {
  @ViewChild("menuViewOverlay") menuViewOverlay: ElementRef;

  public OverlayWith$: Observable<number> = of(0);
  public breakpointMobileLg$: Observable<boolean> = of(false);
  isOverlayOpen: boolean;
  public listaFavoritos$: Observable<string[]> = of([]);

  ramos = [null, null, null, null];
  ramosMobile = [null, null, null, null];
  constructor(
    private uiService: UiService,
    private usuarioGuiasService: UsuarioGuiasService
  ) {}

  ngOnInit(): void {
    this.breakpointMobileLg$ = this.uiService.getBreakpointMobileLg();
  }

  ngAfterViewChecked(): void {
    this.OverlayGetMinWith();
  }

  abrirPrimeiroRamos(valor) {
    if (this.ramos[0] == valor) {
      this.Overlayclose();
    } else {
      this.OverlayOpen();

      this.ramos[0] = valor;
      this.fechaRamos(1);
      if (this.uiService.isBreakpointMobileLg) {
        this.fechatodosRamos();
        this.ramos[0] = valor;
      }
    }
  }

  abrirSegundoRamos(valor) {
    this.ramos[1] = valor;
    this.fechaRamos(2);
    if (this.uiService.isBreakpointMobileLg) {
      this.fechatodosRamos();
      this.ramos[1] = valor;
    }
  }

  abrirTerceiroRamos(valor) {
    this.ramos[2] = valor;
    this.fechaRamos(3);
    if (this.uiService.isBreakpointMobileLg) {
      this.fechatodosRamos();
      this.ramos[2] = valor;
    }
  }

  abrirQuartoRamos(valor) {
    this.ramos[3] = valor;
  }

  fechaRamos(ramo) {
    this.ramos.fill(null, ramo);
  }

  fechatodosRamos() {
    this.ramos.fill(null);
  }

  public OverlayGetMinWith() {
    this.OverlayWith$ = of(this.menuViewOverlay?.nativeElement?.clientWidth);
  }

  public OverlayOpen() {
    this.isOverlayOpen = true;
  }

  public Overlayclose() {
    this.isOverlayOpen = false;
    this.fechatodosRamos();
  }

  public async selecionar(id?: string, titulo?: string): Promise<void> {
    if (!id || !titulo) return;
    const guia = new Guia();
    guia.idLei = id;
    guia.titulo = titulo;
    await this.usuarioGuiasService.novaGuia(guia);
    this.Overlayclose();
  }
}
