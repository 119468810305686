import locale from "@angular/common/locales/pt";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { AppComponent } from "./app.component";
import { LeitorComponent } from "./components/leitor/leitor.component";
import { NavbarComponent } from "./components/navbar/navbar.component";
import { IndicatorsComponent } from "./components/indicators/indicators.component";
import { SynchronizationService } from "./services/synchronization.service";
import { IndicatorsService } from "./services/indicators.service";
import { LeitorToolbarComponent } from "./components/leitor-toolbar/leitor-toolbar.component";
import { LeitorContentComponent } from "./components/leitor-content/leitor-content.component";
import { LeitorStatusbarComponent } from "./components/leitor-statusbar/leitor-statusbar.component";
import { LeitorContentTabcontentComponent } from "./components/leitor-content-tabcontent/leitor-content-tabcontent.component";
import { SelectionService } from "./services/selection.service";
import { PaineisService } from "./components/leitor-content-tabcontent/paineis.service";
import { LeitorContentPanelBuscaComponent } from "./components/leitor-content-panelbusca/leitor-content-panelbusca.component";
import { StatusService } from "./services/status.service";
import { BuscaService } from "./services/busca.service";
import { BarraNavegacaoComponent } from "./components/leitor/barra-navegacao/barra-navegacao.component";
import { ConteudoService } from "./services/conteudo.service";
import { PaginaComponent } from "./components/leitor/pagina/pagina.component";
import { LinhaComponent } from "./components/leitor/pagina/linha/linha.component";
import { TrechoTextoComponent } from "./components/leitor/pagina/linha/trecho-texto/trecho-texto.component";
import { HoverService } from "./services/hover.service";
import { PlaceholderPaineisComponent } from "./components/leitor/placeholder-paineis/placeholder-paineis.component";
import { PlaceholderPaineisService } from "./components/leitor/placeholder-paineis/placeholder-paineis.service";
import { PainelComentariosComponent } from "./components/leitor/placeholder-paineis/painel-comentarios/painel-comentarios.component";
//azure card 358 Desativar botão caiu em prova import { PainelMarcacoesProvaComponent } from "./components/leitor/placeholder-paineis/painel-marcacoes-prova/painel-marcacoes-prova.component";
import { TagPickerComponent } from "./components/leitor/placeholder-paineis/painel-marcacoes-prova/tag-picker/tag-picker.component";
import { InfoPanelComponent } from "./components/leitor-statusbar/info-panel/info-panel.component";
import { IndicadorLeituraComponent } from "./components/leitor-statusbar/indicador-leitura/indicador-leitura.component";
import { TimerComponent } from "./components/navbar/timer/timer.component";
import { SplashComponent } from "./components/splash/splash.component";
import { SplashService } from "./components/splash/splash.service";
import { ResetSenhaComponent } from "./components/auth/reset-senha/reset-senha.component";
import { NovidadesComponent } from "./components/novidades/novidades.component";
import { NovidadesService } from "./components/novidades/novidades.service";
import { TimerService } from "./services/timer.service";
import { NovaGuiaComponent } from "./components/leitor/nova-guia/nova-guia.component";
import { BtnToolbarComponent } from "./components/leitor-toolbar/btn-toolbar/btn-toolbar.component";
import { PainelLateralComponent } from "./components/timer/painel-lateral/painel-lateral.component";
import { SchedulerComponent } from "./components/timer/scheduler/scheduler.component";
import { PainelLateralService } from "./components/timer/painel-lateral/painel-lateral.service";
import { LeitorContentPanelBuscaAvancadaComponent } from "./components/leitor-content-panelbuscaavancada/leitor-content-panelbuscaavancada.component";
import { UiService } from "./services/ui.service";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";
import { PaginadorComponent } from "./components/paginador/paginador.component";
import { UiScrollModule } from "ngx-ui-scroll";
import { LeitorContentPanelSpinnerComponent } from "./components/leitor-content-panelspinner/leitor-content-panelspinner.component";
import { ItensVerificarService } from "./services/storage-services/itens.verificar.service";
import { ShortcutsService } from "./services/shortcuts.service";
import { VirtualScrollerModule } from "ngx-virtual-scroller";
import { ItemMenuNovaGuiaComponent } from "./components/leitor/nova-guia/item-menu-nova-guia/item-menu-nova-guia.component";
import { PrimeiroLoginComponent } from "./components/popups/primeiro-login/primeiro-login.component";
import { PopupsComponent } from "./components/popups/popups.component";
import { PopupsService } from "./components/popups/popups.service";
import { PerfilComponent } from "./components/popups/perfil/perfil.component";
import { FormIdentificacaoComponent } from "./components/popups/perfil/form-identificacao/form-identificacao.component";
import { FormAlterarSenhaComponent } from "./components/popups/perfil/form-alterar-senha/form-alterar-senha.component";
import { FormPagamentoComponent } from "./components/popups/perfil/form-pagamento/form-pagamento.component";
import { PreferenciasComponent } from "./components/popups/preferencias/preferencias.component";
import { SimNaoDialogComponent } from "./components/dialogs/sim-nao-dialog/sim-nao-dialog.component";
import { ColorPickerComponent } from "./components/dialogs/color-picker/color-picker.component";
import { FormCancelamentoComponent } from "./components/popups/perfil/form-cancelamento/form-cancelamento.component";
import { FormIndicacoesComponent } from "./components/popups/perfil/form-indicacoes/form-indicacoes.component";
import { DatePipe } from "@angular/common";
import { AngularFireModule } from "@angular/fire";
import { NovaGuiaService } from "./components/leitor/nova-guia/nova-guia.service";
import { InfoLeiComponent } from "./components/leitor/nova-guia/info-lei/info-lei.component";
import { LeitorContentPanelBuscaProgressoComponent } from "./components/leitor-content-panelbuscaprogresso/leitor-content-panelbuscaprogresso.component";
import { UsuarioGuiasService } from "./services/data-services/usuario.guias.service";
import { UsuarioPreferenciasService } from "./services/data-services/usuario.preferencias.service";
import { ErroPopUpComponent } from "./components/popups/mensagem/erro-popup.component";
import { UsuarioEstatisticasService } from "./services/data-services/usuario.estatisticas.service";
import { UsuarioMarcacoesService } from "./services/data-services/usuario.marcacoes.service";
import { UsuarioComentariosService } from "./services/data-services/usuario.comentarios.service";
import { UsuarioGrifosService } from "./services/data-services/usuario.grifos.service";
import { UsuarioAnotacoesService } from "./services/data-services/usuario.anotacoes.service";
import { UsuarioApontamentosService } from "./services/data-services/usuario.apontamentos.service";
// azure card 358 Desativar botão caiu em prova
import { UsuarioProvasService } from "./services/data-services/usuario.provas.service";
import { DialogoComponent } from "./components/popups/dialogo/dialogo.component";
import { DialogoService } from "./components/popups/dialogo/dialogo.service";
import { SignalrService } from "./services/signalr.service";
import { DadosService } from "./services/dados.service";
import { ViewLabComponent } from "./components/view-lab/view-lab.component";
import { MaterialModule } from "./helpers/material.module";
import { routing } from "./helpers/app.routing";
import { PopupCarregamentoComponent } from "./components/popups/popup-carregamento/popup-carregamento.component";
import { MdePopoverModule } from "@material-extended/mde";
import {
  MarcacaoSuperiorComponent,
  ResizableDivDirective,
  AutoGrowDivDirective as AutoGrowDivDirective,
  AutoWidhtDirective,
} from "./components/leitor/pagina/marcacao-superior/marcacao-superior.component";
import { RefreshTokenInterceptor } from "./interceptors/refresh-token.interceptor";
import { LoggingService } from "./services/logging.service";
import { AbaLeitorComponent } from "./components/leitor/barra-navegacao/aba-leitor/aba-leitor.component";
import { BackgroundContrastDirective } from "./directives/background-contrast.directive";
//azure card 358 Desativar botão caiu em prova import { ChipProvaComponent } from "./components/leitor/pagina/marcacao-superior/chip-prova/chip-prova.component";
import { FeedbackComponent } from "./components/popups/feedback/feedback.component";
import { MaskDateDirective } from "./directives/mask-date.directive";
import { TextFormatingService } from "./services/ui/textFormating.service";
import { ControleFonteComponent } from "./controls/leitor/controle-fonte/controle-fonte.component";
import { IncompatibleBrowserComponent } from "./pages/incompatible-browser/incompatible-browser.component";
import { CheckForUpdateService } from "./services/checkForUpdateService";
import { ErrorHandler, LOCALE_ID, NgModule } from "@angular/core";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { NgxIndexedDBModule } from "ngx-indexed-db";
import { dbConfig } from "./idb.config";
import { registerLocaleData } from "@angular/common";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { LeiRepositorio } from "./repositorios/lei.repositorio";
import { EstatisticasRepositorio } from "./repositorios/estatisticas.repositorio";
import { DataControleRepositorio } from "./repositorios/dataControle.repositorio";
import { LeiService } from "./services/lei.service";
import { FormBuscaArtigoDocumentoAtualComponent } from "./controls/busca/form-busca-artigo-documento-atual/form-busca-artigo-documento-atual.component";
import { SortByPipe } from "./pipes/sort.by.type.pipe";
import { EditorComentarioComponent } from "./components/leitor/editors/editor-comentario/editor-comentario.component";
import { CabecalhoComponent } from "./components/leitor/pagina/cabecalho/cabecalho.component";
import { IndiceSistematicoComponent } from "./components/popups/indice-sistematico/indice-sistematico.component";
import { ErrorHandlerService } from "./services/errorHandler.service";
import { ErrorLogRepositorio } from "./repositorios/errorLog.repositorio";
import { CoreModule } from "./modules/core/core.module";
import { SharedModule } from "./modules/shared/shared.module";
import { BrowserModule } from "@angular/platform-browser";
import { StarRatingComponent } from "./components/star-rating/star-rating.component";
import { EditorTagsComponent } from "./components/leitor/editors/editor-tags/editor-tags.component";
import { QuizComponent } from "./components/leitor/quiz/quiz.component";
import { UsuarioComentariosGerenciadosService } from "./services/data-services/usuario.comentarios.gerenciados.service";
import { ConfigDialogComponent } from "./components/popups/config-dialog/config-dialog.component";
import { EditorReferenciaComponent } from "./components/leitor/editors/editor-referencias/editor-referencia.component";
import { UsuarioReferenciaService } from "./services/data-services/usuario.referencia.service";
import { ReferenciaDialogComponent } from "./components/popups/referencia-dialog/referencia-dialog.component";
import { FeatureFlagService } from "./services/feature-flag-service.service";
import { DicaEstudosComponent } from "./components/popups/dica-estudos/dica-estudos.component";
import { QuillModule } from "ngx-quill";
import { RichTextComponent } from "./components/rich-text/rich-text.component";
import { UsuarioReferenciasGerenciadasService } from "./services/data-services/usuario.referencias.gerenciadas.service";
import { ApplicationInsightsService } from "./services/application-insights.service";
import { TituloComponent } from "./components/leitor/editors/editor-comentario/titulo/titulo.component";
import { ComentarioComponent } from "./components/leitor/editors/editor-comentario/comentario/comentario.component";
import { CommonModule } from "@angular/common";
import { HistoricoAtualizacoesComponent } from "./components/navbar/historico-atualizacoes/historico-atualizacoes.component";
import { HistoricoAtualizacoesDialogComponent } from "./components/popups/historico-atualizacoes-dialog/historico-atualizacoes-dialog.component";
import { NavegacaoPesquisaLeisComponent } from "./components/navbar/navegacao-pesquisa-leis/navegacao-pesquisa-leis.component";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { ItemNavegacaoPesquisaLeisComponent } from "./components/navbar/navegacao-pesquisa-leis/item-navegacao-pesquisa-leis/item-navegacao-pesquisa-leis.component";
import { OverlayModule } from "@angular/cdk/overlay";
import { PortalModule } from "@angular/cdk/portal";
import { NavegacaoLeisRamosDireitoComponent } from './components/navbar/navegacao-leis-ramos-direito/navegacao-leis-ramos-direito.component';
import { ItemNavegacaoFavoritosComponent } from './components/navbar/navegacao-favoritos/item-navegacao-favoritos/item-navegacao-favoritos.component';
import { NavegacaoFavoritosComponent } from './components/navbar/navegacao-favoritos/navegacao-favoritos.component';

registerLocaleData(locale);

@NgModule({
  declarations: [
    AppComponent,
    LeitorComponent,
    NavbarComponent,
    IndicatorsComponent,
    LeitorToolbarComponent,
    LeitorContentComponent,
    LeitorStatusbarComponent,
    LeitorContentTabcontentComponent,
    BarraNavegacaoComponent,
    LeitorContentPanelBuscaComponent,
    PaginaComponent,
    LinhaComponent,
    TrechoTextoComponent,
    PlaceholderPaineisComponent,
    PainelComentariosComponent,
    //azure card 358 Desativar botão caiu em prova PainelMarcacoesProvaComponent,
    TagPickerComponent,
    InfoPanelComponent,
    PaginadorComponent,
    IndicadorLeituraComponent,
    TimerComponent,
    SplashComponent,
    ResetSenhaComponent,
    NovidadesComponent,
    NovaGuiaComponent,
    BtnToolbarComponent,
    PainelLateralComponent,
    SchedulerComponent,
    LeitorContentPanelBuscaAvancadaComponent,
    LeitorContentPanelSpinnerComponent,
    ItemMenuNovaGuiaComponent,
    PopupsComponent,
    PrimeiroLoginComponent,
    PerfilComponent,
    FormIdentificacaoComponent,
    FormAlterarSenhaComponent,
    FormPagamentoComponent,
    FormCancelamentoComponent,
    FormIndicacoesComponent,
    PreferenciasComponent,
    SimNaoDialogComponent,
    ColorPickerComponent,
    InfoLeiComponent,
    LeitorContentPanelBuscaProgressoComponent,
    ErroPopUpComponent,
    DialogoComponent,
    ViewLabComponent,
    PopupCarregamentoComponent,
    MarcacaoSuperiorComponent,
    FeedbackComponent,
    ResizableDivDirective,
    AutoGrowDivDirective,
    AutoWidhtDirective,
    AbaLeitorComponent,
    BackgroundContrastDirective,
    //azure card 358 Desativar botão caiu em prova  ChipProvaComponent,
    MaskDateDirective,
    ControleFonteComponent,
    IncompatibleBrowserComponent,
    FormBuscaArtigoDocumentoAtualComponent,
    SortByPipe,
    EditorComentarioComponent,
    CabecalhoComponent,
    IndiceSistematicoComponent,
    SortByPipe,
    QuizComponent,
    StarRatingComponent,
    EditorTagsComponent,
    ConfigDialogComponent,
    EditorReferenciaComponent,
    ReferenciaDialogComponent,
    DicaEstudosComponent,
    RichTextComponent,
    TituloComponent,
    ComentarioComponent,
    HistoricoAtualizacoesComponent,
    HistoricoAtualizacoesDialogComponent,
    NavegacaoPesquisaLeisComponent,
    ItemNavegacaoPesquisaLeisComponent,
    NavegacaoLeisRamosDireitoComponent,
    ItemNavegacaoFavoritosComponent,
    NavegacaoFavoritosComponent,
  ],
  entryComponents: [
    SimNaoDialogComponent,
    ColorPickerComponent,
    InfoLeiComponent,
    LeitorContentPanelBuscaProgressoComponent,
    ErroPopUpComponent,
    DialogoComponent,
    PopupCarregamentoComponent,
    ConfigDialogComponent,
    ReferenciaDialogComponent,
    HistoricoAtualizacoesDialogComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    SharedModule,
    CoreModule,
    FormsModule,
    HttpClientModule,
    MaterialModule,
    ReactiveFormsModule,
    routing,
    UiScrollModule,
    VirtualScrollerModule,
    MdePopoverModule,
    // NgxSocialLoginModule.init({
    //   google: {
    //     client_id:
    //       '700120466349-eftprtv7cthgi8mimc409sb25c6t0035.apps.googleusercontent.com',
    //   },
    //   facebook: {
    //     initOptions: {
    //       appId: '298495030720106',
    //     },
    //     loginOptions: {
    //       scope: 'email',
    //     },
    //   },
    // }),
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
    }),
    AngularFireModule.initializeApp(environment.firebase),
    BrowserAnimationsModule,
    NgxIndexedDBModule.forRoot(dbConfig),
    QuillModule.forRoot(),
    ScrollingModule,
    OverlayModule,
    PortalModule,
  ],
  providers: [
    // Repositórios
    LeiRepositorio,
    EstatisticasRepositorio,
    DataControleRepositorio,
    ErrorLogRepositorio,
    // Serviços
    LeiService,
    BuscaService,
    ConteudoService,
    DadosService,
    DatePipe,
    DialogoService,
    HoverService,
    IndicatorsService,
    ItensVerificarService,
    LeiService,
    LoggingService,
    NovaGuiaService,
    NovaGuiaService,
    NovidadesService,
    PaineisService,
    PainelLateralService,
    PlaceholderPaineisService,
    PopupsService,
    SelectionService,
    ShortcutsService,
    SignalrService,
    SplashService,
    StatusService,
    SynchronizationService,
    TextFormatingService,
    TimerService,
    UiService,
    UsuarioGuiasService,
    UsuarioPreferenciasService,
    UsuarioEstatisticasService,
    UsuarioMarcacoesService,
    UsuarioComentariosService,
    UsuarioGrifosService,
    UsuarioAnotacoesService,
    UsuarioApontamentosService,
    // azure card 358 Desativar botão caiu em prova
    UsuarioProvasService,
    ApplicationInsightsService,
    ErrorHandlerService,
    { provide: LOCALE_ID, useValue: "pt" },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshTokenInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: ErrorHandlerService,
    },
    CheckForUpdateService,
    UsuarioComentariosGerenciadosService,
    UsuarioReferenciaService,
    FeatureFlagService,
    UsuarioReferenciasGerenciadasService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
