import { Component, OnInit } from "@angular/core";
import { Observable, of } from "rxjs";
import { UsuarioEstatisticasService } from "src/app/services/data-services/usuario.estatisticas.service";
import { UiService } from "src/app/services/ui.service";
import { NavegacaoFavoritosService } from "./navegacao-favoritos.service";
import { LeiLookup } from "src/app/models/lei/lei.lookup";

@Component({
  selector: "app-navegacao-favoritos",
  templateUrl: "./navegacao-favoritos.component.html",
  styleUrls: ["./navegacao-favoritos.component.scss"],
})
export class NavegacaoFavoritosComponent implements OnInit {
  public breakpointMobileLg$: Observable<boolean> = of(false);

  public listaFavoritos$: Observable<LeiLookup[]> = of([]);
  isOverlayOpen: boolean = false;

  constructor(
    private uiService: UiService,
    private usuarioEstatisticasService: UsuarioEstatisticasService,
    private navegacaoFavoritosService: NavegacaoFavoritosService
  ) {}

  async ngOnInit(): Promise<void> {
    this.breakpointMobileLg$ = this.uiService.getBreakpointMobileLg();
    await this.navegacaoFavoritosService.LeisfavotirasPaginada();

    this.listaFavoritos$ =
      this.navegacaoFavoritosService.getLeisFavoritasLookup();
  }

  public async favoritoAlterardo(item) {
    const estatisticas = await this.usuarioEstatisticasService.marcarFavorito(
      item.id
    );
    item.favorita = estatisticas.favorito;
  }

  public async OverlayOpen() {
    this.isOverlayOpen = !this.isOverlayOpen;
    if (this.isOverlayOpen) {
      await this.navegacaoFavoritosService.LeisfavotirasPaginada();
    }
  }
}
