import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-item-navegacao-favoritos",
  templateUrl: "./item-navegacao-favoritos.component.html",
  styleUrls: ["./item-navegacao-favoritos.component.scss"],
})
export class ItemNavegacaoFavoritosComponent implements OnInit {
  item: any;

  @Input() set setItem(setItem) {
    this.item = setItem;
  }

  @Output() favorito_mudou: EventEmitter<boolean> = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}
  public btnFavorito_click(evt: Event): void {
    evt.stopPropagation();
    this.favorito_mudou.emit(!this.item.favorita);
  }
}
