<div class="menu-navegacao-container-favorito">
  <mat-divider
    *ngIf="!(breakpointMobileLg$ | async)"
    class="menu-divider"
    [vertical]="true"
  ></mat-divider>

  <div
    class="menu-button menu-button-favorito"
    mat-button
    type="button"
    cdkOverlayOrigin
    #favoritosOverlay="cdkOverlayOrigin"
    #favoritosViewOverlay
    [ngClass]="{ openedmenufavorito: isOverlayOpen }"
    (click)="OverlayOpen()"
  >
    <mat-icon
      class="navegacao-menu-button-icon navegacao-menu-button-icon-favorito"
      svgIcon="star"
    ></mat-icon>
    <p>Leis Favoritas</p>
    <mat-icon
      class="navegacao-menu-button-icon"
      [svgIcon]="isOverlayOpen ? 'caret-up' : 'caret-down'"
    ></mat-icon>
  </div>
</div>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="favoritosOverlay"
  [cdkConnectedOverlayOpen]="isOverlayOpen"
  cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
  [cdkConnectedOverlayHasBackdrop]="true"
  (detach)="isOverlayOpen = false"
  (backdropClick)="OverlayOpen()"
  [cdkConnectedOverlayPanelClass]="'navegacao-leis-favoritos-Overlay-custom'"
>
  <div class="navegacao-leis-favoritos-custom">
    <ng-container *ngIf="(listaFavoritos$ | async)?.length > 0; else Empty">
      <cdk-virtual-scroll-viewport
        itemSize="10"
        minBufferPx="200"
        maxBufferPx="400"
        class="navegacao-favoritos-virtual-scroll-viewport"
      >
        <app-item-navegacao-favoritos
          *cdkVirtualFor="let item of listaFavoritos$"
          [setItem]="item"
          class="navegacao-favoritos-virtual-scroll-item"
          (favorito_mudou)="favoritoAlterardo(item)"
        >
        </app-item-navegacao-favoritos>
      </cdk-virtual-scroll-viewport>
    </ng-container>
  </div>
</ng-template>

<ng-template #Empty></ng-template>
