<span class="placeholder flex-row center-start">
  <span class="favorito">
    <button mat-icon-button [disableRipple]="true">
      <mat-icon svgIcon="search"></mat-icon>
    </button>
  </span>
  <span class="descricao flex-column flex-1">
    <span class="titulo">{{ item.titulo }}</span>
    <ng-container
      *ngIf="!item.atualizacaoPendente; else elseItemAtualizacaoPendente"
    >
      <span
        class="data-alteracao"
        *ngIf="item.dataHoraUltimaModificacaoOficial"
      >
        Última alteração em:
        {{ item.dataHoraUltimaModificacaoOficial | date : "dd/MM/yyyy" }}
      </span>
      <span
        class="data-alteracao"
        *ngIf="!item.dataHoraUltimaModificacaoOficial"
      >
        Última alteração indefinida
      </span>
    </ng-container>

    <ng-template #elseItemAtualizacaoPendente>
      <span class="data-alteracao">
        <mat-chip-list>
          <mat-chip disabled class="chip-atualizacao">
            documento em processo de atualização</mat-chip
          >
        </mat-chip-list>
      </span>
    </ng-template>
  </span>
</span>
