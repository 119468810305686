<span class="placeholder flex-row center-start">
  <span class="favorito">
    <button mat-icon-button (click)="btnFavorito_click($event)">
      <mat-icon>{{ item.favorita ? "bookmark" : "bookmark_border" }}</mat-icon>
    </button>
  </span>
  <span class="descricao flex-column flex-1">
    <span class="titulo">{{ item.titulo }}</span>
  </span>
</span>
