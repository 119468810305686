import { Injectable } from "@angular/core";
import { EstatisticasLeitura } from "../../models/usuario/EstatisticasLeitura";
import {
  SignalrService,
  OperacaoSignalR,
  EnumTipoObjeto,
} from "../signalr.service";
import { EstatisticasRepositorio } from "../../repositorios/estatisticas.repositorio";
import { BehaviorSubject, Observable } from "rxjs";
import { LeiRepositorio } from "../../repositorios/lei.repositorio";

@Injectable()
export class UsuarioEstatisticasService {
  private leisFavoritas: string[] = [];
  private leisFavoritas$ = new BehaviorSubject<string[]>(this.leisFavoritas);

  constructor(
    private signalrService: SignalrService,
    private leiRepositorio: LeiRepositorio,
    private estatisticasRepositorio: EstatisticasRepositorio
  ) {
    signalrService.$Mensagem.subscribe((msg) => this.onMessageReceived(msg));
  }

  async listar(): Promise<EstatisticasLeitura[]> {
    const listaEstatisticas = await this.estatisticasRepositorio.listar();

    this.leisFavoritas = listaEstatisticas
      .filter((l) => l.favorito)
      .map((l) => l.id);
    this.updateLeisFavoritas();
    return listaEstatisticas;
  }

  async buscar(idLei: string): Promise<EstatisticasLeitura> {
    let estatisticas = await this.estatisticasRepositorio.buscar(idLei);
    return estatisticas;
  }

  private onMessageReceived(msg: OperacaoSignalR): void {
    if (msg && msg.tipoObjeto === EnumTipoObjeto.Estatisticas) {
      this.salvar(msg.dados, false);
    }
  }

  public async salvarLote(
    listaEstatisticas: EstatisticasLeitura[],
    sync = true
  ): Promise<void> {
    for (const estatisticas of listaEstatisticas) {
      await this.estatisticasRepositorio.salvar(estatisticas);
    }

    if (sync) {
      const mensagens = listaEstatisticas.map((estatisticas) => {
        const msg = new OperacaoSignalR();
        msg.dados = estatisticas;
        return msg;
      });

      await this.signalrService.enviarMensagens(
        mensagens,
        EnumTipoObjeto.Estatisticas
      );
    }
  }

  public async salvar(
    estatisticas: EstatisticasLeitura,
    sync = true
  ): Promise<void> {
    await this.estatisticasRepositorio.salvar(estatisticas);

    if (sync) {
      const mensagem = new OperacaoSignalR();
      mensagem.dados = estatisticas;

      await this.signalrService.enviarMensagem(
        mensagem,
        EnumTipoObjeto.Estatisticas
      );
    }
  }

  public async marcarLido(
    idLei: string,
    idLinha: string
  ): Promise<EstatisticasLeitura> {
    const estatisticas = await this.buscar(idLei);

    const iLinha = estatisticas.linhasLidas.findIndex((l) => l === idLinha);
    if (iLinha === -1) estatisticas.linhasLidas.push(idLinha);
    else estatisticas.linhasLidas.splice(iLinha, 1);

    await this.salvar(estatisticas);
    return estatisticas;
  }

  public async marcarVariosLidos(
    idLei: string,
    idsLinhas: string[]
  ): Promise<EstatisticasLeitura> {
    const estatisticas = await this.buscar(idLei);

    idsLinhas.forEach((idLinha) => {
      const iLinha = estatisticas.linhasLidas.findIndex((l) => l === idLinha);
      if (iLinha === -1) estatisticas.linhasLidas.push(idLinha);
    });

    await this.salvar(estatisticas);
    return estatisticas;
  }

  public async marcarFavorito(id: string): Promise<EstatisticasLeitura> {
    const estatisticas = await this.buscar(id);
    estatisticas.favorito = !estatisticas.favorito;

    await this.salvar(estatisticas);
    await this.listar();

    return estatisticas;
  }

  public async alterarPosicaoLeitura(idLei: string, idLinha: string) {
    const estatisticas = await this.buscar(idLei);
    estatisticas.idUltimoItemLido = idLinha;

    await this.salvar(estatisticas);
  }

  updateLeisFavoritas() {
    this.leisFavoritas$.next(this.leisFavoritas);
  }

  getLeisFavoritas(): Observable<string[]> {
    return this.leisFavoritas$.asObservable();
  }
}
